<template>
  <div>
     <v-snackbar
      v-model="snackbar.show"
      :timeout="snackbar.timeout"
      :color="snackbar.color"
      top
    >
      <v-icon>{{ snackbar.icon }}</v-icon>
      <span class="white--text ml-3">{{ snackbar.text }}</span>

      <template v-slot:action="{ attrs }">
        <v-btn
          color="secondary"
          text
          v-bind="attrs"
          @click="snackbar.show = false"
        >
          Fechar
        </v-btn>
        <v-btn
          v-if="snackbar.confirmationBtn"
          color="secondary"
          text
          @click="snackbar.confirm()"
        >
          Confirmar
        </v-btn>
      </template>
    </v-snackbar>
    <v-dialog v-model="dialog" persistent max-width="800">
      <ClientsForm
        :client="client"
        :isNew="isNew"
        @close="dialog = false"
        @update="updateClient"
        @create="createClient"
      />
    </v-dialog>
    <Table
      :data="data.items ? data : getClients()"
      @edit="editClient"
      @delete="deleteClient"
      @new="newClient"
    />
  </div>
</template>

<script>
export default {
  components: {
    Table: () => import('@/components/data-table/Table'),
    ClientsForm: () => import('./ClientsForm.vue'),
  },
  data: () => ({
    dialog: false,
    isNew: false,
    client: {
      status: true,
      name: null,
      contact: null,
      is_whatsapp: null,
      cep: null,
      address: null,
      number: null,
      district: null,
      uf: null,
      city: null
    },
    data: {
      items: [],
      isLoading: true,
      headers: [
        {text: 'Nome', value: 'name'},
        {text: 'Status', value: 'status', align: 'center'},
        {text: 'Email', value: 'email'},
        {text: 'Criado em', value: 'created_at_formated'},
        {text: 'Ações', value: 'actions', align: 'center'},
      ]
    },
    snackbar: {
      color: 'success',
      show: false,
      icon: 'mdi-check-circle',
      confirmationBtn: false,
      confirm: Function,
      negate: Function,
      text: '',
      timeout: 2000,
    }
  }),
  methods: {
    /*
    ** Métodos http
    */
    getClients() {
      this.$api.get('people/profile/4')
        .then(res => {
          this.data.items = res.data.data
        })
        .catch(err => {
          console.log(err)
        })
        .finally(() => {
          this.data.isLoading = false
        })
    },
    createClient() {
      this.$api.post('people', {...this.client, profile_id: 4})
        .then(() => {
          this.snackbar = {
            show: true,
            color: 'success',
            text: 'Cliente cadastrado com sucesso',
            icon: 'mdi-check-circle',
          }
        })
        .catch(() => {
          this.snackbar = {
            show: true,
            color: 'error',
            text: 'Ops! Ocorreu um erro',
            icon: 'mdi-alert-octagon',
          }
        })
        .finally(() => {
          this.getClients()
        })
    },
    deleteClient(item) {
      this.snackbar = {
        show: true,
        color: 'warning',
        text: 'Deseja excluir este cliente?',
        icon: 'mdi-alert',
        confirmationBtn: true,
        timeout: -1,
        confirm: () => {
          this.$api.delete(`people/${item.id}`)
            .then(() => {
              this.snackbar = {
                show: true,
                color: 'success',
                text: 'Cliente excluído com sucesso',
                icon: 'mdi-check-circle',
                timeout: 2000,
              }
              this.getClients()
            })
            .catch(() => {
              this.snackbar = {
                show: true,
                color: 'error',
                text: 'Ops! Ocorreu um erro',
                icon: 'mdi-alert-octagon',
                timeout: 2000,
              }
            })
        },
        negate: () => {
          this.snackbar = {}
        }
      }
      
    },
    updateClient() {
      this.$api.patch(`people/${this.client.id}`, this.client)
        .then(() => {
          this.snackbar = {
            show: true,
            color: 'success',
            text: 'Cliente atualizado com sucesso',
            icon: 'mdi-check-circle',
          }
        })
        .catch(() => {
          this.snackbar = {
            show: true,
            color: 'error',
            text: 'Ops! Ocorreu um erro',
            icon: 'mdi-alert-octagon',
          }
        })
        .finally(() => {
          this.getClients()
        })
    },

    /*
    ** Ouvindo eventos da tabela (novo, editar, excluir)
    */
    newClient() {
      this.dialog = true
      this.isNew = true
    },
    editClient(item) {
      this.dialog = true
      this.isNew = false
      this.$api.get(`people/${item.id}`)
        .then(res => {
          this.client = res.data.data
        })
        .catch(err => {
          console.log(err)
        })
    },
  },
  created() {
    this.getClients()
  },
}
</script>